import { IFaqData } from '@app/api/content.api';
import { IBody } from '@app/api/table.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { LANGUAGE_TABS } from '@app/constants/content';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { createFaq, getFaqDataById, updateFaq } from '@app/store/actions';
import { FormProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const CreateEditFaq = () => {
  const data = useAppSelector((state) => state.content.faqEditData);

  const [activeTab, setActiveTab] = useState<string>(LANGUAGE_TABS.us);

  const { TextArea } = BaseInput;

  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  console.log('id', id);

  const commonTabs = useMemo(
    () => [
      {
        key: LANGUAGE_TABS.us,
        label: `${t('tabs.us')}`,
      },
      {
        key: LANGUAGE_TABS.hy,
        label: `${t('tabs.hy')}`,
      },
      {
        key: LANGUAGE_TABS.ru,
        label: `${t('tabs.ru')}`,
      },
      {
        key: LANGUAGE_TABS.cn,
        label: `${t('tabs.cn')}`,
      },
    ],
    [t],
  );

  const handleTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    setActiveTab(activeKey);
  };

  const onFinish: FormProps<IBody>['onFinish'] = (values) => {
    if (id) {
      dispatch(updateFaq({ data: values, id: Number(id) })).then(() => navigate(-1));
    } else {
      dispatch(createFaq({ data: values })).then(() => navigate(-1));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getFaqDataById({ id: Number(id) }));
    }
  }, []);

  useEffect(() => {
    if (data && id) {
      for (const key in data) {
        const value = key as keyof IFaqData;
        form.setFieldValue(key, data[value]);
      }
    }
  }, [data]);

  return (
    <>
      <BaseCol className="fitWidth">
        <BaseTabs defaultActiveKey="1" items={commonTabs} onTabClick={handleTabClick}></BaseTabs>
      </BaseCol>
      <BaseCol>
        <BaseForm form={form} layout="vertical" name="userForm" onFinish={onFinish}>
          <div style={{ display: activeTab === LANGUAGE_TABS.hy ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`question_hy`}
              label={t('common.question')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.question')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="answer_hy"
              label={t('common.answer')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <TextArea placeholder={t('common.answer')} rows={4} />
            </BaseForm.Item>
          </div>
          <div style={{ display: activeTab === LANGUAGE_TABS.us ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`question_en`}
              label={t('common.question')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.question')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="answer_en"
              label={t('common.answer')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <TextArea placeholder={t('common.answer')} rows={4} />
            </BaseForm.Item>
          </div>
          <div style={{ display: activeTab === LANGUAGE_TABS.ru ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`question_ru`}
              label={t('common.question')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.question')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="answer_ru"
              label={t('common.answer')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <TextArea placeholder={t('common.answer')} rows={4} />
            </BaseForm.Item>
          </div>
          <div style={{ display: activeTab === LANGUAGE_TABS.cn ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`question_cn`}
              label={t('common.question')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.question')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="answer_cn"
              label={t('common.answer')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <TextArea placeholder={t('common.answer')} rows={4} />
            </BaseForm.Item>
          </div>
          <BaseButton type="primary" htmlType="submit">
            Save
          </BaseButton>
        </BaseForm>
      </BaseCol>
    </>
  );
};

export default CreateEditFaq;
