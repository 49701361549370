import { FC } from 'react';

import './style.css';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface ICard {
  cardHolder: string;
  cardNumber: string;
  icon: string;
}

const imageBase = process.env.REACT_APP_IMAGE_URL;

export const SingleCard: FC<ICard> = ({ cardHolder, cardNumber, icon }) => {
  const isAmex = cardNumber.startsWith('34') || cardNumber.startsWith('37');
  const maskedCard = isAmex
    ? cardNumber.replace(/^(\d{4})(\d{6})(\d{5})$/, '$1 $2 $3')
    : cardNumber.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, '$1 $2 $3 $4');

  return (
    <div className="single_card_section">
      <div className="card_info">
        <img src={`${imageBase}${icon}`} alt="Visa" className="card_icon" crossOrigin="anonymous" />
        <div className="card_data">
          <p className="card_text">{cardHolder}</p>
          <BaseTooltip className="" title={maskedCard} color="black">
            <BaseRow>
              <p className="card_text">{maskedCard}</p>
            </BaseRow>
          </BaseTooltip>
        </div>
      </div>
    </div>
  );
};
