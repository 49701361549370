import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { deleteToken, readToken } from '@app/services/localStorage.service';

const pendingRequests = new Map<string, () => void>();

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  cancelable?: boolean;
}

function getRequestKey(config: AxiosRequestConfig): string {
  return `${config.method}:${config.url}`;
}

function cancelPendingRequest(key: string): void {
  if (pendingRequests.has(key)) {
    const cancel = pendingRequests.get(key);
    if (cancel) cancel();
    pendingRequests.delete(key);
  }
}

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config: CustomAxiosRequestConfig) => {
  const requestKey = getRequestKey(config);

  if (config.cancelable) {
    cancelPendingRequest(requestKey);

    config.cancelToken = new axios.CancelToken((cancel) => {
      pendingRequests.set(requestKey, cancel);
    });
  }

  config.headers = config.headers || {};
  config.headers['Authorization'] = `Bearer ${readToken()}`;

  return config;
});

httpApi.interceptors.response.use(
  (response) => {
    const requestKey = getRequestKey(response.config);
    pendingRequests.delete(requestKey);
    return response;
  },
  (error) => {
    const requestKey = getRequestKey(error.config || {});
    pendingRequests.delete(requestKey);

    if (axios.isCancel(error)) {
    } else if (error.response && error.response.status === 401) {
      deleteToken();
      if (window.location.pathname !== '/auth/login') window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export const httpFormApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpFormApi.interceptors.request.use((config: CustomAxiosRequestConfig) => {
  const requestKey = getRequestKey(config);

  if (config.cancelable) {
    cancelPendingRequest(requestKey);

    config.cancelToken = new axios.CancelToken((cancel) => {
      pendingRequests.set(requestKey, cancel);
    });
  }

  config.headers = config.headers || {};
  config.headers['Content-Type'] = 'multipart/form-data';

  return config;
});

httpFormApi.interceptors.response.use(
  (response) => {
    const requestKey = getRequestKey(response.config);
    pendingRequests.delete(requestKey);
    return response;
  },
  (error) => {
    const requestKey = getRequestKey(error.config || {});
    pendingRequests.delete(requestKey);

    if (axios.isCancel(error)) {
    } else if (error.response && error.response.status === 401) {
      deleteToken();
      if (window.location.pathname !== '/auth/login') window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export interface ApiErrorData {
  message: string;
}
