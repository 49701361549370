import { createSlice } from '@reduxjs/toolkit';

import { getTrades, getTradeRequests, getChatMessages } from '../actions/trades';
import { IChatMessages, ITradesSlice } from '@app/api/trades.api';
import { TRADE_REQUEST } from '@app/constants/global';

const initialState: ITradesSlice = {
  data: [],
  tradeLoading: false,
  tradeTotal: 0,
  tradeRequests: [],
  requestLoading: false,
  requestTotal: 0,
  priceRate: '',
  tradeRequest: TRADE_REQUEST,
  messages: [],
  messagesLoading: false,
};

const tradesSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {
    setTradeRequest: (state, { payload }) => {
      state.tradeRequest = payload;
    },
    setChatMessages: (state, { payload }) => {
      state.messages = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrades.pending, (state) => {
        state.tradeLoading = true;
      })
      .addCase(getTrades.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.tradeTotal = payload?.total;
        state.tradeLoading = false;
      })
      .addCase(getTrades.rejected, (state) => {
        state.tradeLoading = false;
      });
    builder
      .addCase(getTradeRequests.pending, (state) => {
        state.requestLoading = true;
      })
      .addCase(getTradeRequests.fulfilled, (state, { payload }) => {
        state.tradeRequests = payload?.data;
        state.requestTotal = payload?.total;
        state.priceRate = payload?.priceRate;
        state.requestLoading = false;
      })
      .addCase(getTradeRequests.rejected, (state) => {
        state.requestLoading = false;
      });
    builder
      .addCase(getChatMessages.pending, (state) => {
        state.messagesLoading = true;
      })
      .addCase(getChatMessages.fulfilled, (state, { payload }) => {
        state.messages = payload?.data?.sort((a: IChatMessages, b: IChatMessages) => {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        });
        state.messagesLoading = false;
      })
      .addCase(getChatMessages.rejected, (state) => {
        state.messagesLoading = false;
      });
  },
});

export const { setTradeRequest, setChatMessages } = tradesSlice.actions;

export default tradesSlice.reducer;
