import { IMainSection } from '@app/api/content.api';
import { IBody } from '@app/api/table.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { EditorComponent } from '@app/components/textEditor/TextEditor';
import { LANGUAGE_TABS, CONTENT_KEYS } from '@app/constants/content';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { createTermsOfUse, getTermsOfUse, updateTermsOfUse } from '@app/store/actions';
import { FormProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { notificationController } from '@app/controllers/notificationController';

const TermsOfUse = () => {
  const [editorValueEn, setEditorValueEn] = useState('');
  const [editorValueHy, setEditorValueHy] = useState('');
  const [editorValueRu, setEditorValueRu] = useState('');
  const [editorValueCn, setEditorValueCn] = useState('');

  const data = useAppSelector((state) => state.content.termsOfUse);

  const [activeTab, setActiveTab] = useState<string>(LANGUAGE_TABS.us);

  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const commonTabs = useMemo(
    () => [
      {
        key: LANGUAGE_TABS.us,
        label: `${t('tabs.us')}`,
      },
      {
        key: LANGUAGE_TABS.hy,
        label: `${t('tabs.hy')}`,
      },
      {
        key: LANGUAGE_TABS.ru,
        label: `${t('tabs.ru')}`,
      },
      {
        key: LANGUAGE_TABS.cn,
        label: `${t('tabs.cn')}`,
      },
    ],
    [t],
  );

  const onFinishFailedDefault = (error: ValidateErrorEntity<unknown>) => {
    notificationController.error({
      message: t('common.error'),
      description: t('common.formErrorMsg'),
    });
  };

  const handleTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    setActiveTab(activeKey);
  };

  const onFinish: FormProps<IBody>['onFinish'] = (values) => {
    if (data) {
      dispatch(updateTermsOfUse({ data: values }));
    } else {
      dispatch(createTermsOfUse({ data: values }));
    }
  };

  useEffect(() => {
    dispatch(getTermsOfUse());
  }, []);

  useEffect(() => {
    if (editorValueEn) {
      form.setFieldValue(CONTENT_KEYS.content_en, editorValueEn);
    }
  }, [editorValueEn]);

  useEffect(() => {
    if (editorValueHy) {
      form.setFieldValue(CONTENT_KEYS.content_hy, editorValueHy);
    }
  }, [editorValueHy]);

  useEffect(() => {
    if (editorValueRu) {
      form.setFieldValue(CONTENT_KEYS.content_ru, editorValueRu);
    }
  }, [editorValueRu]);

  useEffect(() => {
    if (editorValueCn) {
      form.setFieldValue(CONTENT_KEYS.content_cn, editorValueCn);
    }
  }, [editorValueCn]);

  useEffect(() => {
    if (data) {
      for (const key in data) {
        const value = key as keyof IMainSection;
        form.setFieldValue(key, data[value]);
      }
    }
  }, [data]);

  return (
    <>
      <BaseCol className="fitWidth">
        <BaseTabs defaultActiveKey="1" items={commonTabs} onTabClick={handleTabClick}></BaseTabs>
      </BaseCol>
      <BaseCol>
        <BaseForm
          form={form}
          layout="vertical"
          name="userForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailedDefault}
        >
          <div style={{ display: activeTab === LANGUAGE_TABS.hy ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`title_hy`}
              label={t('common.title')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.title')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="content_hy"
              label={t('common.content')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <EditorComponent data={data?.content_hy} setEditorValue={setEditorValueHy} />
            </BaseForm.Item>
          </div>
          <div style={{ display: activeTab === LANGUAGE_TABS.us ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`title_en`}
              label={t('common.title')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.title')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="content_en"
              label={t('common.content')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <EditorComponent data={data?.content_en} setEditorValue={setEditorValueEn} />
            </BaseForm.Item>
          </div>
          <div style={{ display: activeTab === LANGUAGE_TABS.ru ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`title_ru`}
              label={t('common.title')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.title')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="content_ru"
              label={t('common.content')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <EditorComponent data={data?.content_ru} setEditorValue={setEditorValueRu} />
            </BaseForm.Item>
          </div>
          <div style={{ display: activeTab === LANGUAGE_TABS.cn ? 'block' : 'none' }}>
            <BaseForm.Item
              name={`title_cn`}
              label={t('common.title')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <BaseInput placeholder={t('common.title')} />
            </BaseForm.Item>
            <BaseForm.Item
              name="content_cn"
              label={t('common.content')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <EditorComponent data={data?.content_cn} setEditorValue={setEditorValueCn} />
            </BaseForm.Item>
          </div>
          <BaseButton type="primary" htmlType="submit">
            Save
          </BaseButton>
        </BaseForm>
      </BaseCol>
    </>
  );
};

export default TermsOfUse;
