import React, { useState, useEffect, useMemo } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { IBody, Pagination } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { TABLES } from '@app/constants/tables';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { FAQ_DATA, LANGUAGE_TABS } from '@app/constants/content';
import { deleteFaq, getFaqData } from '@app/store/actions';
import { setFaqEditData } from '@app/store/slices/contentSlice';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

export const FaqTreeTable: React.FC = () => {
  const [form] = BaseForm.useForm();
  const [activeTab, setActiveTab] = useState<string>(LANGUAGE_TABS.us);
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const data = useAppSelector((state) => state.content.faqData);
  const total = useAppSelector((state) => state.content.faqTotal);
  const loading = useAppSelector((state) => state.content.faqLoading);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = new URLSearchParams(useLocation().search);
  const selectedPage = query.get(TABLES.page);

  const [page, setPage] = useState<Pagination>({
    current: selectedPage ? Number(selectedPage) : TABLES.first_page,
    pageSize: TABLES.per_page,
    total: total ?? 1,
  });

  const { isMounted } = useMounted();
  const dispatch = useAppDispatch();

  const handleTableChange = (pagination: Pagination) => {
    setPage(pagination);
    query.set(TABLES.page, `${pagination?.current}`);
    navigate(`?${query.toString()}`);
  };

  const handleEdit = (id: string) => {
    dispatch(setFaqEditData(FAQ_DATA));
    navigate(`edit/${id}`);
  };

  const handleDeleteRow = (id: string) => {
    setIsModalActive(true);
    setDeleteId(Number(id));
  };

  const handleDeleteFaq = () => {
    const params = {
      page: page.current,
      per_page: page.pageSize,
      language: 'all',
    };
    dispatch(deleteFaq({ id: Number(deleteId), params: params }));
    setIsModalActive(false);
  };

  const columns = useMemo(
    () => [
      {
        title: t('common.question'),
        dataIndex: `question_${activeTab}`,
        key: `question_${activeTab}`,
        width: '25%',
        render: (text: string, record: IBody) => {
          return (
            <BaseTooltip title={record?.[`question_${activeTab}`]} color="black">
              <BaseRow>
                <span className="cut_text">{record?.[`question_${activeTab}`]}</span>
              </BaseRow>
            </BaseTooltip>
          );
        },
      },
      {
        title: t('common.answer'),
        dataIndex: `answer_${activeTab}`,
        key: `answer_${activeTab}`,
        width: '25%',
        render: (text: string, record: IBody) => {
          return (
            <BaseRow>
              <span className="cut_text">{record?.[`answer_${activeTab}`]}</span>
            </BaseRow>
          );
        },
      },
      {
        title: t('tables.actions'),
        dataIndex: `answer_${activeTab}`,
        key: `answer_${activeTab}`,
        width: '25%',
        render: (text: string, record: IBody) => {
          return (
            <BaseSpace>
              <BaseButton type="ghost" onClick={() => handleEdit(record.id)}>
                {t('common.edit')}
              </BaseButton>
              <BaseButton type="default" danger onClick={() => handleDeleteRow(record.id)}>
                {t('tables.delete')}
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ],
    [activeTab],
  );

  const commonTabs = useMemo(
    () => [
      {
        key: LANGUAGE_TABS.us,
        label: `${t('tabs.us')}`,
      },
      {
        key: LANGUAGE_TABS.hy,
        label: `${t('tabs.hy')}`,
      },
      {
        key: LANGUAGE_TABS.ru,
        label: `${t('tabs.ru')}`,
      },
      {
        key: LANGUAGE_TABS.cn,
        label: `${t('tabs.cn')}`,
      },
    ],
    [t],
  );

  const handleTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    setActiveTab(activeKey);
    query.set(TABLES.page, `${TABLES.first_page}`);
    navigate(`?${query.toString()}`);
  };

  useEffect(() => {
    if (isMounted) {
      const params = {
        page: page.current,
        per_page: page.pageSize,
        language: 'all',
      };
      dispatch(getFaqData({ params }));
    }
  }, [page]);

  return (
    <BaseForm form={form} component={false}>
      <BaseCol className="fitWidth">
        <BaseTabs defaultActiveKey="1" items={commonTabs} onTabClick={handleTabClick}></BaseTabs>
      </BaseCol>
      <BaseTable
        columns={columns}
        dataSource={data}
        pagination={page}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
      />
      <BaseModal
        title={t('modals.userActivation')}
        open={isModalActive}
        onOk={handleDeleteFaq}
        onCancel={() => setIsModalActive(false)}
      >
        <p>{t('modals.delete_faq')}</p>
      </BaseModal>
    </BaseForm>
  );
};
