import { FC, Fragment, MouseEvent, RefObject, memo, useEffect, useState } from 'react';

import { IChatMessages } from '@app/api/trades.api';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from '@app/hooks/reduxHooks';

import './style.css';
import { IMAGES_COUNT, MOBILE_WINDOW_WIDTH } from '@app/constants/global';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useTranslation } from 'react-i18next';
import { ChatImagesModal } from './ChatImagesModal';
import { ADMIN_ACTION_MESSAGE, MESSAGE_NOT_TYPES, SHOW_SEE_CHAT_BUTTON } from '@app/constants/tables';
import moment from 'moment';

const imageUrl = process.env.REACT_APP_IMAGE_URL;

interface IMessageData {
  messages: IChatMessages[];
  messagesEndRef: RefObject<HTMLDivElement>;
}

export const ChatMessages: FC<IMessageData> = memo(({ messages, messagesEndRef }) => {
  const [showImagesModal, setShowImagesModal] = useState<boolean>(false);
  const [showAllImages, setShowAllImages] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[] | File[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const user = useAppSelector((state) => state.user.user);
  const tradeRequest = useAppSelector((state) => state.trades.tradeRequest);
  const { t } = useTranslation();

  const innerWidth = window.innerWidth;
  const shownImagesCount = innerWidth > MOBILE_WINDOW_WIDTH ? IMAGES_COUNT.desktop : IMAGES_COUNT.mobile;

  const handleShowAllImages = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowAllImages(true);
  };

  const handleImagePreview = (images: string[] | File[], index: number) => {
    setSelectedImageIndex(index);
    setSelectedImages(images);
    setShowImagesModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(0);
    setSelectedImages([]);
    setShowImagesModal(false);
  };

  useEffect(() => {
    if (!showImagesModal) {
      document.body.style.overflow = 'unset';
    }
  }, [showImagesModal]);

  return (
    <div className="chat_messages_section" id="chat_messages_section_id">
      <BaseModal title={t('modals.chat_modal')} open={showImagesModal} onCancel={() => setShowImagesModal(false)}>
        <ChatImagesModal onClose={handleCloseModal} images={selectedImages} selectedImageIndex={selectedImageIndex} />
      </BaseModal>
      <div
        className={`chat_messages ${
          !messages?.length && SHOW_SEE_CHAT_BUTTON.includes(tradeRequest.merchant_status) ? 'chat_empty_messages' : ''
        }`}
      >
        {!messages?.length && SHOW_SEE_CHAT_BUTTON.includes(tradeRequest.merchant_status) && (
          <div>No conversations available</div>
        )}
        {messages?.map((element) => (
          <div
            key={`${element.created_at}`}
            className={`message_field ${element.is_admin ? 'my_messages' : ''} ${
              element.is_admin ? 'admin_message' : ''
            }`}
          >
            {element.sender_id !== user?.id && <p className="sender_name">{element.sender_name}</p>}
            <p className="message">
              {element.is_arbitraged
                ? 'Arbitrage Request'
                : element.request_state
                ? MESSAGE_NOT_TYPES[element.request_state]
                : element.admin_action
                ? ADMIN_ACTION_MESSAGE[element.admin_action]
                : element.message}
            </p>
            {element?.attachmentPaths && !!element?.attachmentPaths?.length && (
              <div className="chat_image">
                {element?.attachmentPaths.map((image, index) =>
                  index > shownImagesCount && !showAllImages ? (
                    <Fragment key={uuidv4()}></Fragment>
                  ) : (
                    <div
                      className={`images_grid_element ${
                        element.attachmentPaths.length === 1 ? 'one_images_grid_element' : ''
                      }`}
                      key={uuidv4()}
                    >
                      <img
                        src={typeof image === 'string' ? `${imageUrl}${image}` : URL.createObjectURL(image)}
                        alt="Attach"
                        crossOrigin="anonymous"
                        className="attach_image"
                        onClick={() => handleImagePreview(element.attachmentPaths, index)}
                        onLoad={() =>
                          messagesEndRef.current?.scrollIntoView({
                            behavior: 'auto',
                          })
                        }
                      />
                      {index === shownImagesCount && !showAllImages && (
                        <div className="last_element" onClick={handleShowAllImages}>
                          {`+${element.attachmentPaths.length - (shownImagesCount + 1)}`}
                        </div>
                      )}
                    </div>
                  ),
                )}
              </div>
            )}
            <div className="message_date">
              <span className="date">{moment(element.created_at).format('HH:mm')}</span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
});
